import React from 'react'
import { PageProps } from 'gatsby'
import services from '../../data/services'
import ServicesHomeBanner from '../../components/ServicesHomeBanner'
import ServicesGrid from '../../components/ServicesGrid'
import { LayoutProps } from '../../components/Layout.context'
import { IBreadcrumbItem } from '../../components/Breadcrumb.context'
import { servicesLink } from '../../data/menu'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

export const servicesBreadcrumbElement: IBreadcrumbItem = {
  label: 'Services',
  href: servicesLink,
}

const Container = styled.div`
  flex: 1;
  background: #fff;
`

const ServicesPage: React.FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Produits et services dédiés à votre smartphone"
          content="Votre iPhone est cassé ? Bénéficiez d’une réparation immédiate selon les standards Apple, pour changer un écran cassé ou pour une batterie épuisée."
        />
      </Helmet>

      <Container>
        <ServicesHomeBanner>
          Découvrez <br /> tous les produits <br /> et services dédiés <br /> à
          votre smartphone
        </ServicesHomeBanner>
        <ServicesGrid items={services} />
      </Container>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: `Retrouvez l'ensemble des services de nos magasins !`,
  breadcrumb: [{ ...servicesBreadcrumbElement, href: undefined }],
  showHighlights: true,
}

export default Object.assign(ServicesPage, {
  layoutProps,
})
